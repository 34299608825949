import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css'; // Import custom styles
import logo from './logo.png'; // Import the logo

function App() {
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [versions, setVersions] = useState([]);
  const [types, setTypes] = useState([]); // State for fuel types
  const [statuses] = useState(['Aprovado', 'Aprovado com apontamento', 'Reprovado']);

  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedVersion, setSelectedVersion] = useState('');
  const [selectedType, setSelectedType] = useState(''); // State for selected fuel type
  const [selectedStatus, setSelectedStatus] = useState('');
  const [km, setKm] = useState(''); // State for KM input

  const [fipePrice, setFipePrice] = useState(null);
  const [suggestedPrice, setSuggestedPrice] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);  // State for error messages

  // Fetch brands on component mount
  useEffect(() => {
    axios.get('/api/brands')
      .then(response => setBrands(response.data))
      .catch(error => console.error('Error fetching brands:', error));
  }, []);

  // Fetch models based on selected brand
  useEffect(() => {
    if (selectedBrand) {
      axios.get('/api/models', { params: { brand: selectedBrand } })
        .then(response => {
          setModels(response.data);
          setSelectedModel('');
          setYears([]);
          setSelectedYear('');
          setVersions([]);
          setSelectedVersion('');
          setTypes([]);
          setSelectedType('');
          setKm(''); // Reset KM
        })
        .catch(error => console.error('Error fetching models:', error));
    }
  }, [selectedBrand]);

  // Fetch years based on selected model
  useEffect(() => {
    if (selectedBrand && selectedModel) {
      axios.get('/api/years', { params: { brand: selectedBrand, model: selectedModel } })
        .then(response => {
          setYears(response.data);
          setSelectedYear('');
          setVersions([]);
          setSelectedVersion('');
          setTypes([]);
          setSelectedType('');
          setKm(''); // Reset KM
        })
        .catch(error => console.error('Error fetching years:', error));
    }
  }, [selectedModel]);

  // Fetch versions based on selected year
  useEffect(() => {
    if (selectedBrand && selectedModel && selectedYear) {
      axios.get('/api/versions', {
        params: {
          brand: selectedBrand,
          model: selectedModel,
          year: selectedYear,
        },
      })
        .then(response => {
          setVersions(response.data);
          setSelectedVersion('');
          setTypes([]);
          setSelectedType('');
          setKm(''); // Reset KM
        })
        .catch(error => console.error('Error fetching versions:', error));
    }
  }, [selectedYear]);

  // Fetch fuel types based on selected year and version
  useEffect(() => {
    if (selectedBrand && selectedModel && selectedYear && selectedVersion) {
      axios.get('/api/types', {
        params: {
          brand: selectedBrand,
          model: selectedModel,
          year: selectedYear,
          version: selectedVersion,
        },
      })
        .then(response => {
          setTypes(response.data);
          setSelectedType('');
          setKm(''); // Reset KM
        })
        .catch(error => console.error('Error fetching fuel types:', error));
    }
  }, [selectedVersion]);

  // Fetch FIPE price based on all selected fields
  const fetchFipePrice = () => {
    axios.get('/api/fipe-price', {
      params: {
        brand: selectedBrand,
        model: selectedModel,
        version: selectedVersion,
        year: selectedYear,
        type: selectedType, // Include type in the API call
      },
    })
    .then(response => {
      const price = parseFloat(response.data.fipe_price);
      setFipePrice(price);
      calculateSuggestedPrice(price);
      setErrorMessage(null);  // Clear previous error
    })
    .catch(error => {
      console.error('Error fetching FIPE price:', error);
      setErrorMessage('Preço FIPE não encontrado para essas opções.');
    });
  };

  // Calculate suggested price based on status and KM
  const calculateSuggestedPrice = (price) => {
    let discountRate = 1;

    // Apply matrix based on Status da Vistoria and KM
    if (selectedStatus === 'Aprovado') {
      if (km < 70000) discountRate = 0.85;
      else if (km <= 100000) discountRate = 0.82;
      else discountRate = 0.81;
    } else if (selectedStatus === 'Aprovado com apontamento') {
      if (km < 70000) discountRate = 0.835;
      else if (km <= 100000) discountRate = 0.81;
      else discountRate = 0.79;
    } else if (selectedStatus === 'Reprovado') {
      if (km < 70000) discountRate = 0.80;
      else if (km <= 100000) discountRate = 0.78;
      else discountRate = 0.76;
    }

    let suggested = price * discountRate;

    // Calculate additional discount based on exceeding KM
    if (selectedYear && km) {
      const currentYear = new Date().getFullYear();
      const carAge = currentYear - selectedYear;
      const expectedKm = carAge * 15000; // Expected KM based on 15,000 km/year

      if (km > expectedKm) {
        const excessKm = km - expectedKm;
        const reduction = excessKm * 0.05; // R$ 0.05 per km above expected
        suggested -= reduction;
      }
    }

    setSuggestedPrice(suggested.toFixed(2));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchFipePrice();
  };

  // Handle KM input change
  const handleKmChange = (e) => {
    const value = e.target.value;
    // Ensure only integer values are accepted
    if (/^\d*$/.test(value)) {
      setKm(value);
    }
  };

  return (
    <div className="App">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <h2>Calculadora de Preço de Seminovos para Locadoras</h2>
      <p className="info-text">
        Os dados dessa calculadora são baseados em modelos de precificação de grandes locadoras. Aproveite ;)
      </p>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-group">
          <label>Marca</label>
          <select value={selectedBrand} onChange={(e) => setSelectedBrand(e.target.value)} className="form-control">
            <option value="">Selecione a marca</option>
            {brands.map((brand, index) => (
              <option key={index} value={brand}>{brand}</option>
            ))}
          </select>
        </div>

        {models.length > 0 && (
          <div className="form-group">
            <label>Modelo</label>
            <select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)} className="form-control">
              <option value="">Selecione o modelo</option>
              {models.map((model, index) => (
                <option key={index} value={model}>{model}</option>
              ))}
            </select>
          </div>
        )}

        {years.length > 0 && (
          <div className="form-group">
            <label>Ano</label>
            <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} className="form-control">
              <option value="">Selecione o ano</option>
              {years.map((year, index) => (
                <option key={index} value={year}>{year}</option>
              ))}
            </select>
          </div>
        )}

        {versions.length > 0 && (
          <div className="form-group">
            <label>Versão</label>
            <select value={selectedVersion} onChange={(e) => setSelectedVersion(e.target.value)} className="form-control">
              <option value="">Selecione a versão</option>
              {versions.map((version, index) => (
                <option key={index} value={version}>{version}</option>
              ))}
            </select>
          </div>
        )}

        {types.length > 0 && (
          <div className="form-group">
            <label>Tipo de Combustível</label>
            <select value={selectedType} onChange={(e) => setSelectedType(e.target.value)} className="form-control">
              <option value="">Selecione o tipo</option>
              {types.map((type, index) => (
                <option key={index} value={type}>{type}</option>
              ))}
            </select>
          </div>
        )}

        {selectedType && (
          <div className="form-group">
            <label>Status da Vistoria Cautelar</label>
            <select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)} className="form-control">
              <option value="">Selecione o status</option>
              {statuses.map((status, index) => (
                <option key={index} value={status}>{status}</option>
              ))}
            </select>
          </div>
        )}

        {selectedStatus && (
          <div className="form-group">
            <label>KM do Carro</label>
            <input
              type="text"
              value={km}
              onChange={handleKmChange}
              className="form-control"
              placeholder="Digite o KM do carro"
            />
          </div>
        )}

        <button type="submit" className="btn btn-primary" disabled={!selectedStatus || !km}>Calcular Preço</button>
      </form>

      {errorMessage && (
        <div className="alert alert-danger">
          <p>{errorMessage}</p>
        </div>
      )}

      {fipePrice !== null && (
        <div className="result-container">
          <h2>Resultado</h2>
          <p>Preço FIPE: R$ {fipePrice.toFixed(2)}</p>
          <p>Preço Sugerido: R$ {suggestedPrice}</p>

          {/* Adicionando o link para o WhatsApp */}
          <p style={{ marginTop: '20px' }}>
            Oferecemos uma consultoria completa para vender seus veículos.
            <br />
            <a
              href="https://wa.me/5511994368435?text=Oi%2C%20utilizei%20a%20ferramenta%20de%20precifica%C3%A7%C3%A3o%20e%20quero%20conhecer%20mais%20sobre%20o%20servi%C3%A7o%20de%20venda%20de%20ve%C3%ADculos."
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: 'bold' }}
            >
              Clique aqui para conhecer.
            </a>
          </p>
        </div>
      )}
    </div>
  );
}

export default App;